import React from "react";
import "./ViedosContent.css";

export default function ViedosContent() {
  return (
    <div className="max-w-[1300px] w-full h-full mx-auto">
      <h1>Youtube Channel</h1>
      <a
        href="https://www.youtube.com/@HiDataManagment"
        className="text-blue-500 underline hover:text-blue-700"
      >
        here
      </a>
    </div>
  );
}
